.application-name {
    margin-top: 10px;
    height: 60px;
    position: absolute;
    top: 160px;
}

.application-description {
margin-top: -14px;
height: 90px;
position: absolute;
top: 250px;
}